export default class VueIubenda {
  static install(app, options) {
    options.callback_ = {
      onConsentGiven: function () {
        console.log("JOOO");
      },
      onConsentRejected: function () {
        console.log("NOO");
      },
    };

    /* eslint-disable-next-line */
    window._iub = window._iub || {};
    /* eslint-disable-next-line */
    _iub.csConfiguration = options;
    const iubenda_stub = document.createElement("script");
    iubenda_stub.setAttribute("src", "//cdn.iubenda.com/cs/ccpa/stub.js");
    document.head.appendChild(iubenda_stub);
    const iubenda_cs = document.createElement("script");
    iubenda_cs.setAttribute("src", "//cdn.iubenda.com/cs/iubenda_cs.js");
    document.head.appendChild(iubenda_cs);

    app.config.globalProperties.$iubenda = options;
  }
}
