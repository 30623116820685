import api from "./api";

const getNews = async (params) => {
  params = params ? params : {};
  params["filter[status][_eq]"] = 'published';
  params.fields = params.fields ? params.fields : "*,user_created.*";
  return await api.get(`/items/news${api.addParams(params)}`);
};

const getNewsById = async (id, params) => {
  params = params ? params : {};
  params["filter[id][_eq]"] = id;
  params.fields = "*,user_created.*";
  return await api.get(`items/news${api.addParams(params)}`);
};

const getNewsBySlug = async (slug, params) => {
  params = params ? params : {};
  params["filter[slug][_eq]"] = slug;
  params.fields = "*,user_created.*";
  return await api.get(`items/news${api.addParams(params)}`);
};

const news = {
  getNews,
  getNewsById,
  getNewsBySlug,
};

export default news;
