import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "home",
  },
  {
    path: "/home",
    component: () => import("@/pages/Home"),
  },
  {
    path: "/news",
    component: () => import("@/pages/News"),
  },
  {
    path: "/news/:id",
    component: () => import("@/pages/News/Details"),
  },
  {
    path: "/contact/:section?/:id?/:team?",
    name: "Contact",
    component: () => import("@/pages/Contact"),
  },
  {
    path: "/sections",
    component: () => import("@/pages/Sections"),
  },
  {
    path: "/sections/:slug",
    component: () => import("@/pages/Sections/Details"),
  },
  {
    path: "/sections/:slug/calendar",
    component: () => import("@/components/special/TennisCalendar"),
  },
  {
    path: "/sections/:slug/calendar/:action/:hash",
    component: () => import("@/components/special/TennisCalendar"),
  },
  {
    path: "/sections/:slug/teams/:sub_slug",
    component: () => import("@/pages/Sections/Teams/Team"),
  },
  {
    path: "/sections/:slug/teams/:sub_slug/players",
    component: () => import("@/pages/Sections/Teams/Team/Players"),
  },
  {
    path: "/sections/:slug/teams/:sub_slug/match-report",
    component: () => import("@/pages/Sections/Teams/Team/GameReport"),
  },
  {
    path: "/sections/:slug/:page",
    component: () => import("@/pages/Page"),
  },
  {
    path: "/galleries/:id",
    component: () => import("@/pages/Gallery"),
  },
  {
    path: "/imprint",
    component: () => import("@/pages/Imprint"),
  },
  {
    path: "/about_us",
    component: () => import("@/pages/AboutUs"),
  },
  {
    path: "/privacy",
    component: () => import("@/pages/Privacy"),
  },
  {
    path: "/statute",
    component: () => import("@/pages/Statute"),
  },
  {
    path: "/calendar",
    component: () => import("@/pages/Calendar"),
  },
  {
    path: "/downloads",
    component: () => import("@/pages/Downloads"),
  },
];

const router = createRouter({
  //base: process.env.BASE_URL,
  scrollBehavior() {
    // always scroll to top
    return { top: 0, behavior: "smooth" };
  },
  history: createWebHistory(),
  routes,
});

export default router;
