<template>
  <div>
    <footer
      class="site-footer footer-overlay bg-img-fix"
      style="
        background-image: url(/images/leah-hetteberg-IoJFOvbqF3c-unsplash.jpg);
        background-position: center bottom;
        background-size: cover;
      "
    >
      <div class="footer-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-sm-6 footer-col-4">
              <div class="widget widget_about">
                <div class="logo-footer">
                  <img src="/images/logo_black.png" alt="" />
                </div>
                <div class="m-tb20">
                  Willkommen beim
                  <div><strong>TSV WUSTROW</strong></div>
                  Hier findest du alle Information rund um unseren Verein.
                </div>
                <ul class="dez-social-icon border dez-social-icon-lg">
                  <li>
                    <a
                      v-if="contactItem.facebook_url"
                      :href="contactItem.facebook_url"
                      target="_blank"
                      class="fa fa-facebook fb-btn"
                    ></a>
                  </li>
                  <li>
                    <a
                      v-if="contactItem.instagram_url"
                      :href="contactItem.instagram_url"
                      target="_blank"
                      class="fa fa-instagram fb-btn"
                    ></a>
                  </li>
                  <li>
                    <a
                      v-if="contactItem.twitter_url"
                      :href="contactItem.twitter_url"
                      target="_blank"
                      class="fa fa-twitter fb-btn"
                    ></a>
                  </li>
                </ul>
                <div>
                  <div style="text-align: center"></div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 footer-col-4">
              <div class="widget recent-posts-entry">
                <h4 class="m-b15 text-uppercase">Aktuelle News</h4>
                <div class="dez-separator bg-primary"></div>
                <div v-if="this.newsItems.length" class="widget-post-bx">
                  <div
                    :key="newsItem.id"
                    v-for="newsItem of newsItems"
                    class="widget-post clearfix"
                  >
                    <div class="dez-post-media">
                      <router-link :to="'/news/' + newsItem.id">
                        <img
                          :src="getImage(newsItem.image, 'x200y160')"
                          :alt="newsItem.title"
                          width="200"
                          height="143"
                        />
                      </router-link>
                    </div>
                    <div class="dez-post-info">
                      <div class="dez-post-header">
                        <h6 class="post-title">
                          <router-link
                            v-show="newsItem.slug != 'default'"
                            class="text-primary"
                            :to="'/sections/' + newsItem.slug"
                          >
                            {{
                              getTitleBySlug(newsItem.slug, section_settings)
                            }}
                          </router-link>
                          <a
                            href="#"
                            class="text-primary"
                            v-show="newsItem.slug == 'default'"
                          >
                            {{
                              getTitleBySlug(newsItem.slug, section_settings)
                            }}
                          </a>
                        </h6>
                      </div>
                      <div class="dez-post-meta">
                        <ul>
                          <li class="post-author">
                            <router-link :to="'/news/' + newsItem.id">
                              {{ newsItem.title }}
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 footer-col-4">
              <div class="widget widget_services">
                <h4 class="m-b15 text-uppercase">Unsere Sektionen</h4>
                <div class="dez-separator bg-primary"></div>
                <ul v-if="this.sectionItems.length" class="sections">
                  <li :key="sectionItem.id" v-for="sectionItem of sectionItems">
                    <router-link :to="'/sections/' + sectionItem.slug">{{
                      sectionItem.title
                    }}</router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-2 col-sm-6 footer-col-4">
              <div class="widget widget_getintuch">
                <h4 class="m-b15 text-uppercase">Kontakt</h4>
                <div class="dez-separator bg-primary"></div>
                <ul v-if="contactItem">
                  <li>
                    <i class="fa fa-map-marker"></i><strong>Adresse</strong>
                    {{ contactItem.address }}
                  </li>
                  <li>
                    <a :href="'tel:' + contactItem.phone">
                      <i class="fa fa-phone"></i><strong>Telefon</strong
                      >{{ contactItem.phone }}
                    </a>
                  </li>
                  <li>
                    <a :href="'mailto:' + contactItem.email">
                      <i class="fa fa-envelope"></i><strong>E-Mail</strong
                      >{{ contactItem.email }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- footer bottom part -->
      <div class="footer-bottom">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 text-left">
              <span>Copyright © 2022 TSV Wustrow</span>
            </div>
            <div class="col-lg-6 text-right">
              <router-link to="/imprint"> Impressum</router-link>
              <router-link to="/privacy"> Datenschutz</router-link>
              <router-link to="/statute"> Satzung</router-link>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <button class="scroltop fa fa-chevron-up"></button>
  </div>
</template>

<script>
import { defineComponent, inject } from "vue";
import { getImage, getTitleBySlug } from "../lib/helper";
import moment from "moment";
import sections from "../lib/api/sections";
import news from "../lib/api/news";
import contacts from "../lib/api/contacts";

export default defineComponent({
  methods: {
    getImage,
    getTitleBySlug,
    getYear(created) {
      return moment(created).format("YYYY");
    },
    getDay(created) {
      return moment(created).format("DD MMM");
    },
  },

  data() {
    return {
      newsItems: [],
      sectionItems: [],
      contactItem: {},
      section_settings: [],
    };
  },
  async mounted() {
    const contactResult = await contacts.getGlobalContact();
    this.contactItem = contactResult.data;
    const sectionsResult = await sections.getSections();
    this.sectionItems = sectionsResult.data;
    const newsResult = await news.getNews({ limit: 3, sort: "-date_created" });
    this.newsItems = newsResult.data;
    console.log(
      (this.$refs.member_login.src =
        "https://easyverein.com/public/tsvwustrow/iframe?whitelabel=true&foreground=ffffff&background=235a98")
    );
  },
  created() {
    this.section_settings = inject("section_settings");
  },
});
</script>

<style lang="scss" scoped>
.logo-footer {
  margin-top: 20px;
  margin: auto;
}
.widget_about {
  text-align: center;
}
.dez-social-icon {
  a {
    color: white;
  }
}
.footer-bottom {
  a {
    color: white;
  }
}
a {
  &:hover {
    color: white;
  }
}
.widget {
  &.widget_getintuch {
    ul {
      text-align: left;
    }
  }
}
.sections {
  max-height: 320px;
  overflow: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
