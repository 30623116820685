import api from "./api";

const getSections = async (params) => {
  params = params ? params : {};
  //params["filter[status][_eq]"] = "published";
  let result = await api.get(`/items/sections${api.addParams(params)}`);

  function compare(a, b) {
    const bandA = a.title;
    const bandB = b.title;

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  result.data.sort(compare);
  return result;
};

const getSectionBySlug = async (slug, params) => {
  params = params ? params : {};
  params.fields =
    "*,training_times.training_times_id.*,contact.contact_id.*,pages.pages_id.*,galleries.gallery_id.*,teams.teams_id.*,teams.teams_id.*.*,teams.teams_id.contact.contact_id.*,teams.teams_id.training_times.training_times_id.*,teams.teams_id.galleries.gallery_id.*,teams.teams_id.players.player_id.*,teams.teams_id.match_report.match_report_id.date,teams.teams_id.match_report.match_report_id.id";
  params["filter[slug._eq]"] = slug;
  //console.log(`items/sections${api.addParams(params)}`);
  return await api.get(`items/sections${api.addParams(params)}`);
};

const sections = {
  getSections,
  getSectionBySlug,
};

export default sections;
