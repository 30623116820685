import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import { plugin, defaultConfig } from "@formkit/vue";
import { de } from "@formkit/i18n";
import VueMatomo from "vue-matomo";
import VueIubenda from "./VueIubenda.js";
import { store } from './store/store.js'



createApp(App)
  .use(store)
  .use(router)
  .use(VueIubenda, {
    ccpaAcknowledgeOnDisplay: true,
    consentOnContinuedBrowsing: false,
    countryDetection: true,
    enableCcpa: true,
    enableLgpd: true,
    floatingPreferencesButtonCaptionColor: "#D67676",
    floatingPreferencesButtonColor: "#FFFFFF",
    floatingPreferencesButtonDisplay: "bottom-left",
    invalidateConsentWithoutLog: true,
    lang: "de",
    lgpdAppliesGlobally: false,
    perPurposeConsent: true,
    siteId: 2798032,
    whitelabel: false,
    cookiePolicyId: 68117367,
    /*
    callback: {
      onConsentGiven: function () {
        console.log("JOOO", this);
      },
      onConsentRejected: function () {
        console.log("NOO");
      },
    },*/
    banner: {
      acceptButtonDisplay: true,
      closeButtonDisplay: false,
      customizeButtonDisplay: true,
      explicitWithdrawal: true,
      listPurposes: true,
      position: "bottom",
      rejectButtonDisplay: true,
    },
  })
  .use(VueMatomo, {
    host: "https://matomo.myportal.de",
    siteId: 1,
    trackerFileName: "matomo",
    router: router,
    enableLinkTracking: true,
    requireConsent: true,
    trackInitialView: true,
    disableCookies: false,
    enableHeartBeatTimer: false,
    heartBeatTimerInterval: 15,
    debug: true,
    userId: undefined,
    cookieDomain: undefined,
    domains: undefined,
    preInitActions: [],
  })
  .use(
    plugin,
    defaultConfig({
      // Define additional locales
      locales: { de },
      // Define the active locale
      locale: "de",
    })
  )
  .mount("#app");
