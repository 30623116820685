<template>
  <Navigation v-if="isFullPage" />
  <router-view />
  <Footer v-if="isFullPage" />
</template>

<script>
import { defineComponent, provide, ref } from "vue";

import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";

import section_settings from "./lib/api/section_settings";

export default defineComponent({
  name: "Layout",
  components: {
    Navigation,
    Footer,
  },
  watch: {
    /** @todo */
    $route(route, from) {
      if (
        from.matched &&
        from.matched[0] &&
        from.matched[0].path === "/galleries/:id"
      ) {
        this.isFullPage = true;
      }
      if (
        route.matched &&
        route.matched[0] &&
        route.matched[0].path === "/galleries/:id"
      ) {
        this.isFullPage = false;
      }
    },
  },
  data: function () {
    return { isFullPage: true, matomo: this.$matomo };
  },
  setup() {
    const section_settings_items = ref([]);
    const sectionSettingsResult = section_settings.getSectionSettings();
    sectionSettingsResult.then((res) => {
      section_settings_items.value = res.data;
    });
    provide("section_settings", section_settings_items);
  },
  methods: {
    setConsentGiven(matomo) {
      console.log("matomo", matomo);
      //console.log("JOOO", this.matomo);
    },
  },
  created() {
    console.log("this.$matomo", this.$matomo);
    this.$iubenda.callback = {
      onPreferenceExpressed: (preference) => {
        console.log("this.$matomo", this.$matomo);
        console.log("onPreferenceExpressed", preference.purposes[4]);
        //console.log(preference);
        if (preference.purposes[4]) {
          console.log("matomo_i", this.$matomo);
          this.setConsentGiven(this.$matomo);
          //console.log("GOOO");
          //matomo.setConsentGiven();
        } else {
          //matomo.forgetConsentGiven();
        }
      },
    };
  },
  mounted() {
    this.matomo = this.$matomo;
    console.log("this.$matomo", this.$matomo);
  },
});
</script>
