import api from "./api";

const getGlobalContact = async (params) => {
  params = params ? params : {};
  return await api.get(`/items/global_contact${api.addParams(params)}`);
};

const getSectionsWithGlobalContact = async (params) => {
  params = params ? params : {};
  params.fields =
    "contact_id.*,sections_id.*,sections_id.teams.teams_id.*,sections_id.teams.teams_id.contact.contact_id.*";
  params["filter[sections_id][_nempty]"] = true;
  const apiResult = await api.get(
    `/items/sections_contact${api.addParams(params)}`
  );

  let result = [];
  apiResult.data.map((item) => {
    result[item.sections_id.slug] = result[item.sections_id.slug]
      ? result[item.sections_id.slug]
      : {
          title: "",
          contacts: [],
          teams: [],
        };
    result[item.sections_id.slug]["title"] = item.sections_id.title;
    result[item.sections_id.slug]["contacts"].push(item.contact_id);
    result[item.sections_id.slug]["teams"] = item.sections_id.teams.map(
      (team) => {
        if (team.teams_id && team.teams_id.contact.length > 0) {
          return {
            team: team.teams_id.team,
            id: team.teams_id.id,
            contacts: team.teams_id.contact.map((contact) => {
              return contact.contact_id;
            }),
          };
        }
      }
    );
  });

  return result;
};

const getContactById = async (id, params) => {
  params = params ? params : {};
  params["filter[id][_eq]"] = id;
  return await api.get(`/items/contact${api.addParams(params)}`);
};

const getSectionContacts = async (params) => {
  console.log("getSectionContacts", params);
  /*
  params = params ? params : {};
  params.fields =
    "contact_id.*,sections_id.teams.teams_id.contact.contact_id.*,sections_id.slug";
  let apiResult = await api.get(
    `/items/sections_contact${api.addParams(params)}`
  );
  apiResult = apiResult.data.filter((item) => {
    return item.sections_id;
  });

  let result = {};

  apiResult.map((item) => {
    result[item.sections_id.slug] = result[item.sections_id.slug]
      ? result[item.sections_id.slug]
      : {};
    result[item.sections_id.slug][item.contact_id.id] = item.contact_id;
    if (item.sections_id.teams) {
      item.sections_id.teams.map((team) => {
        team.teams_id.contact.map((contact) => {
          //console.log(contact.contact_id.email);
          let addTeamContact = true;
          Object.keys(result[item.sections_id.slug]).map((key) => {
            if (
              contact.contact_id.email ===
                result[item.sections_id.slug][key].email ||
              contact.contact_id.email ===
                result[item.sections_id.slug][item.contact_id.id].email
            ) {
              console.log("no", result[item.sections_id.slug][key].email);
              addTeamContact = false;
            }
          });
          if (addTeamContact) {
            result[item.sections_id.slug][contact.contact_id.id] =
              contact.contact_id;
          }
        });
      });
    }
  });

  console.log(result);
  */

  return [];
};

const contact = {
  getGlobalContact,
  getSectionsWithGlobalContact,
  getSectionContacts,
  getContactById,
};

export default contact;
