import axios from "axios";

/*
axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;
*/

//const API_URL = "http://localhost:8080";
const API_URL = "https://tsv-api.myportal.de";
const SERVICE_API_URL = "https://service.tsv.myportal.de";
const API_APP_TOKEN = "HJK&rttr(hkjh$$";

const instance = axios.create({
  baseURL: API_URL,
});

const APP_AUTH_CONFIG = {
  headers: { Authorization: "Bearer " + API_APP_TOKEN },
};

const service_instance = axios.create({
  baseURL: SERVICE_API_URL,
});

const get = async (path, auth) => {
  try {
    const response = await instance.get(path, auth);
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

const post = async (path, data) => {
  try {
    const response = await instance.post(path, data, APP_AUTH_CONFIG);
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

const patch = async (path, data) => {
  try {
    const response = await instance.patch(path, data, APP_AUTH_CONFIG);
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

const remove = async (path) => {
  try {
    const response = await instance.delete(path, APP_AUTH_CONFIG);
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

const servicePost = async (path, data) => {
  try {
    const response = await service_instance.post(path, data);
    return response.data;
  } catch (e) {
    console.log("error", e);
  }
};

const serviceGet = async (path) => {
  try {
    const response = await service_instance.get(path);
    return response.data;
  } catch (e) {
    console.log("error", e);
  }
};

const addParams = (data, meta) => {
  let result = "";
  if (!data) return result;
  result = "?";
  for (const [key, value] of Object.entries(data)) {
    result = result + `${key}=${value}&`;
  }
  if (meta !== false) {
    result = result + "meta=*";
  }

  return result;
};

const api = {
  servicePost,
  serviceGet,
  get,
  post,
  patch,
  remove,
  addParams,
  API_URL,
};

export default api;
