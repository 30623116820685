<template>
  <Layout />
</template>

<script>
import { defineComponent } from "vue";

import Layout from "./Layout.vue";

export default defineComponent({
  name: "App",
  components: {
    Layout,
  },
});
</script>
