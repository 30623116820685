import api from "./api";

const getSectionSettings = async (params) => {
  params = params ? params : {};
  return await api.get(`/items/section_settings${api.addParams(params)}`);
};

const section_settings = {
  getSectionSettings,
};

export default section_settings;
