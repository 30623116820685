<template>
  <header class="site-header header-style-7 dark mo-left">
    <!-- top bar -->
    <div class="top-bar">
      <div class="container">
        <div class="row justify-content-between">
          <div class="dez-topbar-left">
            <ul class="social-line text-center pull-right">
              <li>
                <a :href="'tel:' + contactItem.phone"
                  ><i class="fa fa-phone"></i>
                  <span>{{ contactItem.phone }}</span>
                </a>
              </li>
              <li>
                <a :href="'mailto:' + contactItem.email"
                  ><i class="fa fa-envelope"></i>
                  <span>{{ contactItem.email }}</span></a
                >
              </li>
            </ul>
          </div>
          <div class="dez-topbar-right">
            <ul class="social-line text-center pull-right">
              <li v-if="getUser()">
                <a href="#"
                  ><i class="fa fa-user"></i>
                  <span
                    >{{ getUserData().first_name }}
                    {{ getUserData().last_name }}</span
                  >
                </a>
              </li>

              <li v-if="contactItem.facebook_url">
                <a
                  :href="contactItem.facebook_url"
                  target="_blank"
                  class="fa fa-facebook"
                ></a>
              </li>
              <li v-if="contactItem.instagram_url">
                <a
                  :href="contactItem.instagram_url"
                  target="_blank"
                  class="fa fa-instagram"
                ></a>
              </li>
              <li v-if="contactItem.twitter_url">
                <a
                  :href="contactItem.twitter_url"
                  target="_blank"
                  class="fa fa-twitter"
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- top bar END-->

    <!-- main header -->
    <div class="sticky-header main-bar-wraper navbar-expand-lg">
      <div class="main-bar clearfix">
        <div class="container clearfix">
          <!-- website logo -->
          <div class="logo-header mostion">
            <router-link to="/home"
              ><img src="/images/logo.png" alt="TSV - Wustrow"
            /></router-link>
          </div>
          <!-- nav toggle button -->
          <button
            ref="toogleMobilNavi"
            class="navbar-toggler collapsed navicon justify-content-end"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <!-- main nav -->
          <div
            class="header-nav navbar-collapse collapse nav-dark justify-content-end"
            id="navbarNavDropdown"
          >
            <ul id="nav" class="nav navbar-nav">
              <li>
                <router-link v-on:click="close" to="/home">Home</router-link>
              </li>
              <li>
                <router-link to="/sections">Sektionen</router-link>
                <ul class="sub-menu">
                  <li :key="section.id" v-for="section of sections">
                    <router-link
                      v-on:click="close"
                      :to="'/sections/' + section.slug"
                      >{{ section.title }}</router-link
                    >
                  </li>
                </ul>
              </li>
              <!--<li><router-link to="/location">Sportlerheim</router-link></li>-->
              <li>
                <router-link v-on:click="close" to="/calendar"
                  >Kalender</router-link
                >
              </li>
              <li>
                <router-link v-on:click="close" to="/news">News</router-link>
              </li>
              <li>
                <router-link v-on:click="close" to="/about_us"
                  >Über Uns</router-link
                >
              </li>
              <li>
                <router-link v-on:click="close" to="/contact"
                  >Kontakt</router-link
                >
              </li>
              <li>
                <router-link v-on:click="close" to="/downloads"
                  >Downloads</router-link
                >
              </li>
              <li>
                <router-link v-on:click="close" to="/imprint"
                  >Impressum</router-link
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- main header END -->
  </header>
</template>

<script>
import { defineComponent } from "vue";
import sections from "../lib/api/sections";
import contact from "../lib/api/contacts";

export default defineComponent({
  methods: {
    close: function () {
      if (this.$refs.toogleMobilNavi.getAttribute("aria-expanded") === "true") {
        this.$refs.toogleMobilNavi.click();
      }
    },
    getUser() {
      return this.$store.state.user;
    },
    getUserData() {
      return this.getUser().user;
    },
  },

  data() {
    return {
      sections: [],
      contactItem: {},
      user: {},
    };
  },

  watch: {
    user(user) {
      console.log("cahange", user);
    },
  },

  async created() {
    const contactResult = await contact.getGlobalContact();
    this.contactItem = contactResult.data;
    const secrionsResult = await sections.getSections();
    this.sections = secrionsResult.data;
  },
});
</script>

<style lang="scss" scoped>
@import "../../public/css/variables.scss";

.fa-user {
  padding-right: 10px;
}

#navbarNavDropdown {
  .logo-header {
    display: none;
  }
}
.sticky-header {
  .main-bar {
    button.navbar-toggler {
      outline: none;
      border: 2px solid $primary;
    }
    .logo-header {
      position: absolute;
      top: 10px;
      height: auto;
      width: auto;
    }
  }
  &.is-fixed {
    .main-bar {
      .logo-header {
        transform-origin: left top;
        transform: scale(0.5);
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .main-bar {
      .logo-header {
        transform-origin: left top;
        transform: scale(0.5);
      }
    }
  }
}
.top-bar {
  .container {
    .row {
      .dez-topbar-left {
        ul {
          li {
            i {
              padding-right: 5px;
            }
          }
        }
      }
    }
  }
}

#nav {
  padding: 0;
  a {
    color: white;
    &:hover {
      color: $primary;
    }
    //&.router-link-exact-active {
    &.router-link-active {
      color: $primary;
    }
    //}
  }
}
</style>
