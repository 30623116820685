import api from "./api/api";
import moment from "moment";

export const getImage = (image, present = null) => {
  /** @todo add placeholder */
  const params = present ? `?key=${present}` : "";
  let finalImage = image ? image : "1d6f3cb1-97c7-4da0-bd88-887623d73ab7";
  return finalImage ? `${api.API_URL}/assets/${finalImage}${params}` : "";
};

export const getFileUrl = (id) => {
  return `${api.API_URL}/assets/${id}`;
}

export const cutString = (string, length) => {
  return string.slice(0, length) + (string.length > length ? "..." : "");
};

export const getTitleBySlug = (slug, section_settings) => {
  if (!section_settings) return "Undefined";
  const result = section_settings.find((item) => {
    return item.slug === slug;
  });

  return result ? result.title : "Allgemein";
};

export const getDate = (date, format) => {
  return moment(date).format(format);
};

export const getTime = (time, format) => {
  return moment(time, "hh:mm::ss").format(format);
};

export const findOcc = (arr, key) => {
  let arr2 = [];

  arr.forEach((x) => {
    // Checking if there is any object in arr2
    // which contains the key value
    if (
      arr2.some((val) => {
        return val[key] == x[key];
      })
    ) {
      // If yes! then increase the occurrence by 1
      arr2.forEach((k) => {
        if (k[key] === x[key]) {
          k["occurrence"]++;
        }
      });
    } else {
      // If not! Then create a new object initialize
      // it with the present iteration key's value and
      // set the occurrence to 1
      let a = {};
      a[key] = x[key];
      a["occurrence"] = 1;
      arr2.push(a);
    }
  });

  return arr2;
};

export const userCreatedFullName = (user_created) => {
  return `${user_created.first_name} ${user_created.last_name}`;
};

export const strippedString = (string) => {
  return string.replace(/(<([^>]+)>)/gi, "");
}

export const scrollToTop = (container) => {
  function getOffset(el) {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: _y, left: _x };
  }

  const content = document.querySelector(container);

  if (content) {
    window.scrollTo({
      top: getOffset(content).top - 100,
      behavior: "smooth",
    });
  }
};
