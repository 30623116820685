import { createStore } from 'vuex'

export const store = createStore({
    state() {
        return {
            user: false
        }
    },
    mutations: {
        setUser(state, data) {
            state.user = data
        }
    },
    getters: {},
})